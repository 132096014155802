import React from "react";
import Box from "@mui/material/Box";
import {
    LinkedinIcon,
    LinkedinShareButton,
    XIcon,
    TwitterShareButton,
    FacebookShareButton,
    FacebookIcon,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";

const SocialSharing = ({
    shareUrl = ""
}) => {
    const url = `https://octahire.com${shareUrl}`
    const shareConfig = [
        {
            Component: FacebookShareButton,
            Icon: FacebookIcon,
            props: {
                url: url,
                title: 'Check this out!',
            },
        },
        {
            Component: TwitterShareButton,
            Icon: XIcon,
            props: {
                url: url,
                title: 'Check this out!',
            },
        },
        {
            Component: LinkedinShareButton,
            Icon: LinkedinIcon,
            props: {
                url: url,
                title: 'Check this job!',
                summary: 'Check out this job opportunity.',
            },
        },
        {
            Component: WhatsappShareButton,
            Icon: WhatsappIcon,
            props: {
                url: url,
                title: 'Check this job!',
                summary: 'Check out this job opportunity.',
            },
        },
    ];
    return (
        <Box display="flex" justifyContent="flex-end" gap={"10px"} mt={1} >
            {shareConfig.map(({ Component, Icon, props }, index) => (
                <Component key={index} {...props}>
                    <Icon size={32} round />
                </Component>
            ))}
        </Box>
    )
}
export default SocialSharing;