import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
//MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
//other components
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import TableHeader from "../../../components/Freelancer/TableHeader/TableHeader";
import AllCandidateTable from "../AllCandidateTable";
import MuiTypography from "../../../mui/Typography/MuiTypograpghy";
import { Toaster } from "../../../components/Toaster/Toaster";
import {
  setEditData,
  showJobPost,
  updateJobPost,
} from "../../../redux/Employer/JobPostSlice";
import { Box, Menu, MenuItem } from "@mui/material";
import EmployerLayout from "../../Layouts/Employer/EmployerLayout";
import MuiDivider from "../../../mui/Dividers/MuiDivider";
import OutlinedChip from "../../../components/customChips/OutlinedChip";
import MuiTooltip from "../../../mui/Tooltip/MuiTooltip";
import ShortListCandidatesTable from "../ShortListCandidatesTable";
import { setIsLoading } from "../../../redux/globalSlice";
import ConfirmationDialog from "../../../components/Dialog/ComfirmationDialog";
import ExtrasTooltip from "../../../components/Tooltip/ExtrasTooltip";
//ASSETS
import Airbnb from "../../../assets/images/Airbnb.svg";
import LocationIcon from "../../../assets/images/location.svg";
import SuitcaseIcon from "../../../assets/images/suitcaseIcon.svg";
import CurrencyRupeeIcon from "../../../assets/images/ruppeeIcon.svg";

//CSS
import "./index.scss";
import Skills from "../../JobSeeker/Jobs/Components/Skills";
import { returnSubstring } from "../../../utils";
import MuiIconChip from "../../../mui/Chip/IconChip";
import {
  filterAllCandidates,
  getAllCandidates,
  setACTFilter,
  setACTPage,
  setACTRowCount,
  setAllCandidates,
} from "../../../redux/tableSlice";
import InfoBox from "../../../components/OtherInfoForDetails";
import RenderJD from "./RenderJD";
import FilterMenu from "../FilterMenu";
import { ACTFilterDetailsMenuItems } from "../../../Data";
import FilterIconWithBadge from "../FilterIconWithBadge";
import SocialSharing from "../../../components/SocialSharing/SocialSharing";
import ChatBox from "../../ChatBox/ChatBox";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 23.5,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 1.8,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(26px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#576EDB" : "#576EDB",
        opacity: 1,
        border: 0,
      },
      "& .MuiSwitch-thumb": {
        width: "20px",
        height: "20px",
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#d3d3db" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

let primaryTab = [
  { title: "All Candidates", value: "all_candidate" },
  // { title: `Shortlist Candidates`, value: `shortlist_candidate` },
];
let secondaryTab = [
  { title: "Active Candidates", value: "active_candidate" },
  // { title: `Shortlist Candidates`, value: `shortlist_candidate` },
];

const JobDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const pastOpening = location?.state;
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [openConfirmationPopup, setComfirmationPopup] = useState(false);

  let tabData = pastOpening ? secondaryTab : primaryTab;
  const [value, setValue] = useState(tabData[0]?.value);
  const prefilledDta = useSelector((state) => state?.jobPost?.editJobPost);
  const [isChecked, setIsChecked] = useState(false);
  const { ACTFilter } = useSelector(state => state.tableData);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const shareUrl = `${location?.pathname}`;
  const { showMessage } = useSelector((state) => state.tableData);

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleClick = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleFilter = (param) => {
    dispatch(setACTFilter(param));
    dispatch(setACTPage(1));
    handleClose();
  }

  const locations = prefilledDta?.locations?.map((location) => location.city)?.join(", ");

  useEffect(() => {
    if (params?.id) {
      setDisableSwitch(true);
      dispatch(setIsLoading(true));
      dispatch(showJobPost(params?.id))
        .then((res) => {
          setDisableSwitch(false);
          dispatch(setIsLoading(false));
          if (res.type === "showJobPost/fulfilled") {
            dispatch(setEditData(res.payload.data.attributes));
            // Toaster.TOAST(res?.payload?.message, "success");
            setIsChecked(res.payload.data.attributes?.active);
          } else if (res.type === "showJobPost/rejected") {
            navigate("/open-positions");
          }
        })
        .catch((err) => {
          console.log(err, "error");
          setDisableSwitch(false);
          dispatch(setIsLoading(false));
        });
    }
    return () => {
      dispatch(setACTFilter("all"));
      dispatch(setACTPage(1));
      dispatch(setACTRowCount(null));
    }
  }, [params?.id]);

  const toggleDescription = () => {
    showFullDescription && window.scrollTo(0, 0);
    setShowFullDescription(!showFullDescription);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //switch function

  const handleSwitch = () => {
    setComfirmationPopup(true);
  };

  const toggleJobStatus = () => {
    let data = {
      data: {
        active: !prefilledDta?.active,
      },
    };
    setDisableSwitch(true);
    dispatch(updateJobPost({ data: data, job_id: params?.id }))
      .then((res) => {
        if (res.type === "jobPost/update-job/fulfilled") {
          Toaster.TOAST(
            `Job is successfully ${!isChecked ? "activated" : "deactivated"}. `,
            "success"
          );

          !isChecked ? navigate("/open-positions") : navigate("/past-openings");
          setIsChecked((prevChecked) => !prevChecked);
          setDisableSwitch(false);
        } else {
          setDisableSwitch(false);
        }
      })
      .catch((err) => {
        setDisableSwitch(false);
        console.log(err.message, ".err");
      });
  };

  const handleMaximise = () => {
    navigate('/message')
  }
  return (
    <>
      <EmployerLayout>
        <Card className="border-0 card-job-details-header" sx={{ borderRadius: "10px", mt: 2 }}>
          <CardHeader
            title={
              <span className="job-details-header">
                <span className="job-details-header">
                  <span className="airbnbImage">
                    <img
                      src={prefilledDta?.employer?.profile_photo?.url || Airbnb}
                      alt="Airbnb"
                    />
                  </span>
                  <span className="job-details-header">
                    <span style={{ margin: "4px 15px" }}>
                      <p
                        style={{ display: "flex", gap: "0px 15px", alignItems: "start", }}
                      >
                        <MuiTooltip
                          title={prefilledDta?.job_title}
                          length={25}
                        />
                        <OutlinedChip
                          className="hide_on_screen"
                          style={{
                            transform: "translateY(-2px)"
                          }}
                          size={"medium-100"}
                          label={!prefilledDta?.active ? "Complete" : "Open"}
                          backgroundColor={
                            !prefilledDta?.active ? "#C5F7D2" : "#DEE4FF"
                          }
                          borderColor={
                            !prefilledDta?.active ? "#34A853" : "#576EDB"
                          }
                          color={!prefilledDta?.active ? "#34A853" : "#576EDB"}
                        />
                      </p>
                      <p className="paragraph-14">
                        {prefilledDta?.category?.name}
                      </p>
                    </span>
                  </span>
                </span>

                <span className="show_on_screen" style={{
                  transform: "translateY(5px)"
                }}>
                  <OutlinedChip
                    size={"medium-100"}
                    label={!prefilledDta?.active ? "Complete" : "Open"}
                    backgroundColor={
                      !prefilledDta?.active ? "#C5F7D2" : "#DEE4FF"
                    }
                    borderColor={
                      !prefilledDta?.active ? "#34A853" : "#576EDB"
                    }
                    color={!prefilledDta?.active ? "#34A853" : "#576EDB"}
                  />
                </span>
                <span className="hide_on_screen" style={{ display: "flex" }}>
                  <Stack direction="column">
                    <IOSSwitch
                      sx={{ mr: 1 }}
                      disabled={disableSwitch}
                      checked={isChecked}
                      onChange={handleSwitch}
                    />
                    <Typography
                      sx={{
                        fontSize: "11px",
                        textAlign: "left",
                        opacity: "0.5",
                        transform: "translateX(5px)",
                      }}
                    >
                      {isChecked ? "Active" : "Inactive"}
                    </Typography>
                  </Stack>
                  <span
                    style={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#445386",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid var(--Dark-Stock, #A1A7BC)",
                      borderRadius: "30px",
                      padding: "3px 14px",
                      height: "fit-content",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      navigate(`/job-openings/${params.id}`)
                    }
                  >
                    <ModeEditIcon
                      sx={{ fontSize: "14px", marginRight: "4px", padding: 0 }}
                    />{" "}
                    Edit
                  </span>
                </span>
              </span>
            }
            subheader={
              <>
                <Box className="oh-card-body">
                  {prefilledDta?.work_experience && (
                    <MuiTypography variant="span" className="oh-requirements">
                      <img src={SuitcaseIcon} className="suitcase-icon" alt={"work-exp"} /> {prefilledDta?.work_experience}
                    </MuiTypography>
                  )}

                  {prefilledDta?.ctc && (
                    <MuiTypography variant="span" className="oh-requirements">
                      <img src={CurrencyRupeeIcon} className="rupee-icon" alt={"salary"} /> {prefilledDta?.ctc}
                    </MuiTypography>
                  )}
                  {locations && (
                    <MuiTypography className="oh-requirements">
                      <img src={LocationIcon} className="location-icon" alt={"locations"} />
                      {locations}
                    </MuiTypography>
                  )}

                  {prefilledDta?.created_at && (
                    <MuiTypography
                      className="oh-posted-text hide_on_screen"
                      content={`Posted: ${moment(
                        prefilledDta?.created_at
                      ).fromNow()}`}
                    />
                  )}
                </Box>
                <MuiDivider className="oh-divider" />
              </>
            }
          />
          <div className="show_on_screen" style={{
            padding: "0 16px 16px"
          }}>
            <Box sx={{
              display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap"
            }}>
              {prefilledDta?.created_at && (
                <MuiTypography
                  style={{
                    color: "var(--oh-blue-300-color)",
                    fontSize: "14px",
                    fontWeight: 400,
                    opacity: "0.5",
                    transform: "translateY(-5px)",
                  }}
                  content={`Posted: ${moment(
                    prefilledDta?.created_at
                  ).fromNow()}`}
                />
              )}
              <span style={{ display: "flex" }}>
                <Stack direction="column">
                  <IOSSwitch
                    sx={{ mr: 1 }}
                    disabled={disableSwitch}
                    checked={isChecked}
                    onChange={handleSwitch}
                  />
                  <Typography
                    sx={{
                      fontSize: "11px",
                      textAlign: "left",
                      opacity: "0.5",
                      transform: "translateX(5px)",
                    }}
                  >
                    {isChecked ? "Active" : "Inactive"}
                  </Typography>
                </Stack>
                <span
                  style={{
                    fontWeight: 400,
                    fontSize: "14px",
                    color: "#445386",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid var(--Dark-Stock, #A1A7BC)",
                    borderRadius: "30px",
                    padding: "3px 14px",
                    height: "fit-content",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    navigate(`/job-openings/${params.id}`)
                  }
                >
                  <ModeEditIcon
                    sx={{ fontSize: "14px", marginRight: "4px", padding: 0 }}
                  />{" "}
                  Edit
                </span>
              </span>
            </Box>
            <MuiDivider style={{ marginTop: "5px" }} className="oh-divider" />

          </div>
          <CardContent sx={{ padding: "5px 20px" }}>
            <Box style={{ padding: "10px 0 20px" }}>
              <h4 style={{ marginBottom: "10px" }}>Job Description</h4>

              <RenderJD
                job_description={prefilledDta?.job_description}
                showFullDescription={showFullDescription}
                toggleDescription={toggleDescription}
              />
            </Box>
            <MuiDivider className="oh-divider" />

            <Box style={{ padding: "30px 0 10px" }}>
              <h4>Skills Required</h4>
              <Skills
                size="medium-200"
                skillsData={prefilledDta?.skills?.map((elem) => elem.name)}
              />
            </Box>

            <MuiDivider className="oh-divider" />

            <Box style={{ padding: "30px 0 0" }}>
              <h4 style={{ marginBottom: "10px" }}>Other Information</h4>
              <InfoBox
                items={[
                  {
                    label: "Min. Education Required :-",
                    value: prefilledDta?.education?.education_name || "-",
                  },
                  {
                    label: "Experience Required :-",
                    value: prefilledDta?.work_experience || "-",
                  },
                  {
                    label: "Work Mode :-",
                    value: prefilledDta?.work_mode || "-",
                  },
                  {
                    label: "No. of vacancies :-",
                    value: prefilledDta?.no_of_vacancies || "-",
                  },
                  { label: "CTC Offered :-", value: prefilledDta?.ctc ? `₹ ${prefilledDta?.ctc}` : "-" },
                  {
                    label: "Designation:-",
                    value: prefilledDta?.designation || "-",
                  },
                  {
                    label: "Job Type:-",
                    value: prefilledDta?.job_type || "-",
                  },
                ]}
              />
            </Box>
            <SocialSharing shareUrl={shareUrl} />
          </CardContent>
        </Card>
        <Box
          className="candidate-table-margin"
          sx={{
            borderRadius: "10px",
            marginTop: "20px",
            background: "var(--oh-white-color)",
            border: "1px solid var(--stock, #DEE4FF)",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              padding: "20px",
              fontSize: "16px",
              fontWeight: 600,
              color: "var(--oh-blue-color)",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>All Candidates</span>
            <OutlinedChip
              style={{
                cursor: "pointer"
              }}
              icon={<FilterIconWithBadge filter={ACTFilter} value={'all'} />}
              label="Filter"
              size="medium-300"
              onClick={handleClick}
            />

            <FilterMenu
              anchorEl={anchorEl}
              open={open}
              handleClose={handleClose}
              handleFilter={handleFilter}
              filter={ACTFilter}
              menuItems={ACTFilterDetailsMenuItems}
            />
          </div>

          <AllCandidateTable
          />

          {/*  <ShortListCandidatesTable jobData={prefilledDta} /> */}
        </Box>
        {/* {showMessage && <ChatBox
          chatWrapper="chatWrapper"
          mainContainer="mainContainer"
          handleMaximise={handleMaximise}
        />} */}
      </EmployerLayout>

      {openConfirmationPopup && (
        <ConfirmationDialog
          heading={`${!isChecked ? "Activate" : "Deactivate"
            } "${returnSubstring(prefilledDta?.job_title, 23)}" `}
          popupState={openConfirmationPopup}
          setPopupState={setComfirmationPopup}
          handleYes={toggleJobStatus}
        />
      )}
    </>
  );
};

export default JobDetails;
